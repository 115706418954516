


























































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Description: () => import('./tab-item/description-tab.vue'),
    TokenSale: () => import('./tab-item/token-sale-tab.vue'),
    ContractMetric: () => import('./tab-item/contract-metrics-tab.vue'),
    VestingSchedule: () => import('./tab-item/vesting schedule.vue'),
  },
})
export default class TabCustom extends Vue {
  header = ['Description', 'Token Sale', 'Contract Metrics']
  currentTab = 1
  toggleActive(tabNumber: number) {
    this.currentTab = tabNumber
    this.$emit('toggleActive', tabNumber)
  }
}
